.Pantry-pantry {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.Pantry-pantry-item-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

.Pantry-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
}

.Pantry-button-wrapper {
    display: flex;
    flex-direction: row;
    height: 34px;
    width: 150px;
    margin: 20px 15px;
}

.Pantry-error {
    display: flex;
    justify-content: center;
    align-self: center;
    background: #fff1f1;
    color: #ff6161;
    border: 1px solid #ff6161;
    border-radius: 5px;
    padding: 5px 25px;
    margin: 12px 20px 0px 20px;
}

.Pantry-spacer {
    min-height: 80px;
    width: 100%;
}