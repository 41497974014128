.SearchBar-search-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 20px 5px 20px;
    box-sizing: border-box;
}

.SearchBar-buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.SearchBar-button {
    width: 24px;
    height: 24px;
    border: none;
    background: none;
    padding: 0;
    margin-right: 10px;
}

.SearchBar-button-image {
    height: 24px;
    width: 24px;
}