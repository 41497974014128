.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: rgba(208, 247, 232, 0.842);
}

.App-main {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}

.App-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: hidden;
  flex: 1;
  background-color: white;
  border: none;
}

@media (min-width: 769px) {
  .App-main {
    align-self: center;
    width: 768px;
    margin-bottom: 75px;
  }
  .App-content {
    border-radius: 10px;
    width: 766px;
    border-top: 1px solid rgba(0, 0, 0, 0.274);
    border-left: 1px solid rgba(0, 0, 0, 0.274);
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
}