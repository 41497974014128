.Navigation-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .25em 1.1em .25em 1.1em;
}

.Navigation-app-name {
    font-size: 1.5rem;
}