.Tabs-tabs-container {
    display: flex;
    flex-direction: row;
}

.Tabs-edge {
    width: 40px;
}

.Tabs-tabs-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
}

.Tabs-tabs {
    flex: 1;
    padding: 20px 0px 14px 0px;
    text-align: center;
    z-index: 0;
    background: rgb(211, 247, 253);
    border: 1px solid rgba(0, 0, 0, 0.274);
    border-bottom: transparent;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Tabs-active-tab {
    background: white;
}

.Tabs-cover {
    position: relative;
    width: 100%;
    height: 6px;
    bottom: -80%;
    z-index: 1;
    background: transparent;
}

.Tabs-active-cover {
    background: white;
}

@media (max-width: 769px) {
    .Tabs-tabs {
        border: 1px solid rgba(0, 0, 0, 0.274);
    }
    .Tabs-edge {
        border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    }
}