.AddButton-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    align-self: center;
    position: absolute;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, 0.274);
    border-radius: 50px;
    margin: 15px 15px;
    background: rgb(211, 247, 253);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition-duration: .4s;
}

.AddButton-button:active {
    background: rgb(173, 219, 228);
    transition: all 0.4s;
}

.AddButton-button-image {
    height: 18px;
    width: 18px;
}