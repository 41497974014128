.SearchField-search-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 300px;
    margin-right: 10px;
}

.SearchField-search-icon {
    margin-right: .25rem;
}

.SearchField-search-input {
    flex: 1;
    width: 100%;
    height: 75%;
    border: 1px solid rgba(0, 0, 0, 0.274);
    border-radius: 1.25rem;
    font-size: 0.875rem;
}