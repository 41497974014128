.GroceryItem-grocery-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.GroceryItem-item-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.6250em 1.2500em;
    height: 70px;
}

.GroceryItem-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    height: 100%;
    padding-right: 1em;
    min-width: 80px;
}

.GroceryItem-delete-checkbox-container-left {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GroceryItem-delete-checkbox {
    height: 20px;
    width: 20px;
    margin: 0 15px 0 0;
}

.GroceryItem-name-container-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-wrap: break-word;
}

.GroceryItem-name {
    margin: 0;
    padding: 0 0 0.3125em 0;
    font-size: 1.375rem;
    font-weight: 600;
}

.GroceryItem-name-input {
    flex: 1;
    margin: 0;
    font-size: 1.375rem;
    font-weight: 600;
}

.GroceryItem-unit {
    font-size: 1rem;
    margin: 0;
}

.GroceryItem-button-container-right {
    display: flex;
    flex-direction: row;
}

.GroceryItem-count-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GroceryItem-count-button {
    font-size: 1.25rem;
    min-height: 32px;
    width: 32px;
    border: 1px solid rgba(0, 0, 0, 0.274);
    border-radius: 10px;
}

.GroceryItem-count-button-image {
    height: 12px;
    width: 12px;
}

.GroceryItem-quantity {
    width: 28px;
    text-align: center;
    margin: auto;
}

.GroceryItem-quantity-input {
    height: 22px;
    width: 36px;
    text-align: center;
    margin: auto 63px auto 0;
    border: 0.0625em solid rgba(0, 0, 0, 1);
    padding: 0.0625em;
    font-size: 1rem;
}

.GroceryItem-grocery-checkbox-container-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.GroceryItem-grocery-checkbox {
    height: 20px;
    width: 20px;
    margin: 0 0 0 1.25em;
}

.GroceryItem-border-bottom {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.274);
    width: 100%;
}

@media (max-width: 300px) {
    .GroceryItem-container-left {
        min-width: 60px;
    }

    .GroceryItem-name {
        font-size: 1rem;
    }
    
    .GroceryItem-name-input {
        font-size: 1rem;
    }

    .GroceryItem-count-button {
        min-height: 28px;
        width: 28px;
    }

    .GroceryItem-quantity-input {
        margin: auto 59px auto 0;
    }
}