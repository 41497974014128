.Grocery-grocery {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.Grocery-grocery-item-list {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
}

.Grocery-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
}

.Grocery-button-wrapper {
    display: flex;
    flex-direction: row;
    height: 34px;
    width: 150px;
    margin: 20px 15px;
}

.Grocery-error {
    display: flex;
    justify-content: center;
    align-self: center;
    background: #fff1f1;
    color: #ff6161;
    border: 1px solid #ff6161;
    border-radius: 5px;
    padding: 5px 25px;
    margin: 12px 20px 0px 20px;
}

.Grocery-spacer {
    min-height: 80px;
    width: 100%;
}

.Grocery-checked-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;
}